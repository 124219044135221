<script lang="ts" setup>
</script>

<template>

  <svg viewBox="0 0 102 28" class="dark:text-white text-black" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.66 25H38V10.18H41.36L41.66 11.92C42.41 10.69 43.91 9.72999 46.04 9.72999C48.29 9.72999 49.85 10.84 50.63 12.55C51.38 10.84 53.12 9.72999 55.37 9.72999C58.97 9.72999 60.95 11.89 60.95 15.31V25H57.32V16.27C57.32 14.14 56.18 13.03 54.44 13.03C52.67 13.03 51.32 14.17 51.32 16.6V25H47.66V16.24C47.66 14.17 46.55 13.06 44.81 13.06C43.07 13.06 41.66 14.2 41.66 16.6V25Z" fill="currentColor"/>
    <path d="M65.173 7.20999C63.913 7.20999 62.923 6.21999 62.923 4.98999C62.923 3.75999 63.913 2.79999 65.173 2.79999C66.373 2.79999 67.363 3.75999 67.363 4.98999C67.363 6.21999 66.373 7.20999 65.173 7.20999ZM63.343 25V10.18H67.003V25H63.343Z" fill="currentColor"/>
    <path d="M68.4444 20.5H71.9244C71.9544 21.79 72.9144 22.6 74.5944 22.6C76.3044 22.6 77.2344 21.91 77.2344 20.83C77.2344 20.08 76.8444 19.54 75.5244 19.24L72.8544 18.61C70.1844 18.01 68.8944 16.75 68.8944 14.38C68.8944 11.47 71.3544 9.72999 74.7744 9.72999C78.1044 9.72999 80.3544 11.65 80.3844 14.53H76.9044C76.8744 13.27 76.0344 12.46 74.6244 12.46C73.1844 12.46 72.3444 13.12 72.3444 14.23C72.3444 15.07 73.0044 15.61 74.2644 15.91L76.9344 16.54C79.4244 17.11 80.6844 18.25 80.6844 20.53C80.6844 23.53 78.1344 25.39 74.4744 25.39C70.7844 25.39 68.4444 23.41 68.4444 20.5Z" fill="currentColor"/>
    <path d="M88.9011 25.39C84.4911 25.39 81.4011 22.18 81.4011 17.59C81.4011 12.94 84.4311 9.72999 88.7811 9.72999C93.2211 9.72999 96.0411 12.7 96.0411 17.32V18.43L84.8811 18.46C85.1511 21.07 86.5311 22.39 88.9611 22.39C90.9711 22.39 92.2911 21.61 92.7111 20.2H96.1011C95.4711 23.44 92.7711 25.39 88.9011 25.39ZM88.8111 12.73C86.6511 12.73 85.3311 13.9 84.9711 16.12H92.4111C92.4111 14.08 91.0011 12.73 88.8111 12.73Z" fill="currentColor"/>
    <path d="M99.6003 7.20999C98.3403 7.20999 97.3503 6.21999 97.3503 4.98999C97.3503 3.75999 98.3403 2.79999 99.6003 2.79999C100.8 2.79999 101.79 3.75999 101.79 4.98999C101.79 6.21999 100.8 7.20999 99.6003 7.20999ZM97.7703 25V10.18H101.43V25H97.7703Z" fill="currentColor"/>
    <path d="M19.9235 2.4609C19.5525 2.10956 19.4055 1.95368 18.8105 2.07958C18.3929 2.16952 8.54172 4.33029 4.64278 10.3342C3.69196 11.9769 3.56363 13.4722 3.56363 14.7205C3.56363 17.7314 5.39993 20.5709 8.07622 21.7868C7.2724 22.4487 6.07776 23.3336 5.2436 23.7785C4.71511 24.0603 4.48528 24.7078 4.70694 25.2762C4.96244 25.9345 5.71726 26.2294 6.32858 25.9021C11.8667 22.9427 17.0151 14.9567 17.5156 8.96601C17.5412 8.66024 17.9507 8.43841 18.0347 8.95762C18.4466 11.4889 17.0746 18.6895 13.1791 23.4499C14.5686 24.8037 16.1133 24.8277 16.7584 24.8361C20.7075 24.6742 24.652 21.4103 24.5621 14.4807C24.5201 11.2395 23.2788 6.37955 19.9235 2.4609Z" fill="currentColor"/>
  </svg>


</template>

<style>
</style>
