<script lang="ts" setup>
const store = useStore();


const colorMode = useColorMode();

const isDark = computed({
  get () {
    return colorMode.value === 'dark'
  },
  set () {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  }
});

const isInApp = computed(() => store.isLoggedIn && !store.customsRequired);
</script>

<template>

  <header
    class="relative flex items-center px-4 pb-4 pt-3 mb-[60px]"
    :class="[
      isInApp ? 'justify-between' : 'justify-center'
    ]">

    <ULink :to="store.isLoggedIn ? '/mints' : '/'">
      <AppLogo class="h-[18px] w-auto" />
    </ULink>

    <div
      class="h-full flex items-center justify-end flex-1 mr-4"
      :class="[
        isInApp ? '' : 'absolute right-0 bottom-0.5'
      ]">
      <UButton
        :icon="isDark ? 'i-heroicons-sun-20-solid' : 'i-heroicons-moon-20-solid'"
        color="gray"
        size="xs"
        variant="ghost"
        aria-label="Theme"
        @click="isDark = !isDark"
      />
    </div>


    <div
      v-if="isInApp"
      class="inline-flex items-center space-x-1.5">

      <div class="h-8 pl-2.5 pr-2 opacity-0 animate-fade-in delay-200 text-xs flex items-center border border-gray-300 dark:border-gray-600 rounded-[4px]">

        <div class="flex items-center cursor-alias mr-2">
          {{ textEllipsis(store.account.address, 6) }}
        </div>

        <UiClipboard :text="store.account.address" />

      </div>

      <UButton
        class="px-3 py-1.5 opacity-0 animate-fade-in"
        icon="i-heroicons-arrow-right-start-on-rectangle"
        size="md"
        square
        :padded="false"
        color="gray"
        @click="store.logout">
      </UButton>

    </div>


  </header>

</template>

<style>
</style>
