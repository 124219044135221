<script lang="ts" setup>
const config = useRuntimeConfig();
const store = useStore();
const route = useRoute();

const displayAppNav = computed(() => {
  return store.isLoggedIn && ['/mints', '/wallets', '/portfolio', '/billing'].includes(route.path);
});
</script>

<template>

  <section class="flex justify-center w-full min-h-screen">

    <div class="relative max-w-[520px] w-full border-x border-gray-200 dark:border-gray-700 pb-[200px]">

      <AppBanner
        leading=""
        v-if="config.public.stage === 'edge'">
        <div class="flex items-center">
          <UIcon
          class="mr-2"
          name="i-heroicons-sparkles"/>
          <b class="font-mono">EDGE ENVIRONMENT</b>
        </div>
      </AppBanner>

      <AppHeader />
      <AppNav v-if="displayAppNav"/>


      <div
        v-if="store.pageLoading"
        class="flex justify-center items-center">
        <UIcon
          name="i-eos-icons-bubble-loading"
          class="text-3xl opacity-0 animate-fade-in" />
      </div>
      <template v-else>
        <slot />
      </template>


      <AppFooter class="absolute bottom-0"/>

    </div>

  </section>


</template>

<style>
</style>
