<script lang="ts" setup>
</script>

<template>

  <nav class="flex justify-between px-2 pb-1 pt-4 border-y border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 mb-4">

    <div>

      <UButton
        v-for="link in [
          { label: 'Mints', to: 'mints' },
          { label: 'Wallets', to: 'wallets' },
          { label: 'Portfolio', to: 'portfolio' },
        ]"
        variant="link"
        :to="link.to"
        :class="[
          $route.name === link.to ? 'underline' : 'text-gray-500'
        ]">
        {{ link.label }}
      </UButton>

    </div>

    <div>

      <UButton
        v-for="link in [
          { label: 'Billing', to: 'billing' },
        ]"
        variant="link"
        :to="link.to"
        :class="[
          $route.name === link.to ? 'underline' : 'text-gray-500'
        ]">
        {{ link.label }}
      </UButton>

    </div>

  </nav>

</template>

<style>
</style>
